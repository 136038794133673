<template>
	<div v-if="value">
		<!-- eslint-disable vue/no-v-html -->
		<div class="d-flex justify-space-between align-center mt-0 mb-1">
			<div v-if="showLabel" class="title">
				{{ getLabel }}
			</div>
			<!-- slot "header" - si abilita solo se showLabel=false -->
			<div v-else>
				<slot name="header" />
			</div>
			<div class="d-flex align-end">
				<v-btn fab text small :disabled="enableUpdateAnnoCorrente ? false : meseCorrente===1" @click="downMeseCorrente">
					<v-icon>mdi-chevron-left</v-icon>
				</v-btn>
				<div class="d-flex flex-column align-center " style="min-width:130px">
					<div class="caption opaque" style="margin-bottom">
						Mese calendario
					</div>
					<div class="headline mb-1" style="cursor:pointer" @click="resetMese">
						{{ gMonthName(meseCorrente) }}
					</div>
				</div>
				<v-btn fab text small :disabled="enableUpdateAnnoCorrente ? false : meseCorrente===12" @click="upMeseCorrente">
					<v-icon>mdi-chevron-right</v-icon>
				</v-btn>
			</div>
		</div>
		<v-sheet height="800" :class="loading ? 'opaque' : ''">
			<v-calendar
				
				type="month"
				:value="primaDataAnnoMeseCorrenti"
				:events="value.Eventi"
				:event-color="getEventColor"
				:event-more="false"
				:event-margin-bottom="3"
				@click:event="eventoCalendario"
			/>
			<v-menu
				v-model="selectedOpen"
				:close-on-content-click="false"
				:activator="selectedElement"
				offset-x
			>
				<v-card min-width="350px" flat>
					<v-toolbar dense :color="selectedEvent.color" dark>
						<v-btn icon @click="selectedOpen = false">
							<v-icon>mdi-close</v-icon>
						</v-btn>
						<v-toolbar-title v-html="selectedEvent.name" />
						<v-spacer />
						<v-btn v-if="isEditable" text @click="editEvent(selectedEvent)">
							<v-icon left>
								mdi-pencil
							</v-icon> modifica
						</v-btn>
					</v-toolbar>
					<v-card-text>
						<pre v-html="selectedEvent.details" />
						<v-divider class="mt-3 mb-5" />
						<span v-if="!soloDataCorrente" class="body-1 primary--text">Date:</span>
						<table v-if="decodificaCorso(selectedEvent.id)" class="legacyTable">
							<thead>
								<tr>
									<th>Desc</th>
									<th>Inizio</th>
									<th>Fine</th>
									<th>Sede</th>
									<th>Docente</th>
									<th>Ore</th>
									<th>Costo</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="data in decodificaDateCorso(selectedEvent.id)" :key="data.Id" :class="data.Id === selectedEvent.dataId ? 'selected' : ''">
									<template v-if="!soloDataCorrente || data.Id === selectedEvent.dataId">
										<td class="body-2">
											{{ data.Nome }}
										</td>
										<td class="caption">{{ gShortDate(data.Inizio) }}</td>
										<td class="caption">{{ gShortDate(data.Fine) }}</td>"
										<td class="caption">{{ decodificaNomeSede(data.IdSede) }}</td>
										<td class="caption">
											<div class="d-flex justify-space-between">
												{{ decodificaNomeDocente(data.IdDocente) }}
												<div v-if="data.IdDocente>0" class="ml-2">
													<v-chip v-if="decodificaTipoDocente(data.IdDocente)" color="info" x-small label>
														interno
													</v-chip>
													<v-chip v-else color="" x-small label>
														esterno
													</v-chip>
												</div>
											</div>
										</td>
										<td class="caption">
											{{ data.OreData }}
										</td>
										<td class="caption">
											{{ data.CostoData }}
										</td>
									</template>
								</tr>
							</tbody>
						</table>
					</v-card-text>
				</v-card>
			</v-menu>
		</v-sheet>
		<!-- slot primario -->
		<slot />	
	</div>	
</template>

<script>
/**********************************************************************************************
**** COMPONETE PER LA VISUALIZZAZIONE DEL CALENDARIO CORSI *************************************
***********************************************************************************************
* preleva i dati dallo store quindi è sostanzialmente autonomo
* todo: si potrebbe sostituire anche quello presente in admin/formazione semplicemente agganciando esternamente l'evento di edit del corso 
*/
import moment from 'moment'
import lodash from 'lodash'
export default {
	name: 'HrCalendarioCorsi',
	components: {  },
	props: {
		value: {
			type: Object,
			required: true,
			default: () => {return {}}
		},
		label: {
			type: String,
			required: false,
			default: () => {return null}
		},
		// se truue (default) visualizza la label a sx del selettore mesi
		// se false abilita automaticamente la visualizzazione del contenuto dello slot "header"
		showLabel: {
			type: Boolean,
			required: false,
			default: () => {return true}
		},
		// se true visualizza l'icona di edit nel popup e, al click, emette l'evento "edit" passando l'evento come parametro
		isEditable: {
			type: Boolean,
			required: false,
			default: () => {return false}
		},
		// se true visualizza nella tabella dettaglio solo la data corrente (serve per il calendario primario di Formazione che visualizza le singole adesioni delle persone ai corsi)
		soloDataCorrente: {
			type: Boolean,
			required: false,
			default: () => {return false}
		},
		// serve per indicare al calendario l'anno corrente (che ricordo arrivare dal selettore esterno)
		// va usata con il .sync se si vuole poter alterare l'anno corrente a partire dal selettore mese (ovvero da dicembre passa a gennaio incrementando appunto l'anno)
		annoCorrente : {
			type: Number,
			required: false,
			default: () => {return parseInt(moment().format('YYYY'))}
		},
		// se true consente di modificare l'anno corrente quando il mese va oltre dicembre (e viceversa)
		enableUpdateAnnoCorrente : {
			type: Boolean,
			required: false,
			default: () => {return false}
		},
		// opacizza il calendario durante i loading
		loading: {
			type: Boolean,
			required: false,
			default: () => {return false}
		},
	},
	data: () => {
		return {
			meseCorrente: parseInt(moment().format('M')),
			meseCorrenteIniziale: 0,
			loadingCalendario: false,
			selectedEvent: {},
			selectedElement: null,
			selectedOpen: false,
		}
	},
	computed: {
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		decodifiche() { return this.$store.getters['decodifiche'] },
		// calendarioColori() { return this.$store.getters['calendarioColori'] },
		// calendario() { return this.$store.getters['calendarioCorsi'] },
		primaDataAnnoMeseCorrenti() { return moment(this.annoCorrente + ' ' + this.meseCorrente + ' 1', 'YYYY M D').format('YYYY-MM-DD') },
		getLabel() { return this.label ? this.label : 'Calendario corsi ' + this.annoCorrente }
	},
	mounted() {
		this.meseCorrenteIniziale = this.meseCorrente
	},
	methods: {
		resetMese() {
			this.meseCorrente = this.meseCorrenteIniziale
		},
		upMeseCorrente() {
			if(this.meseCorrente === 12){
				this.meseCorrente = 1
				if(this.enableUpdateAnnoCorrente){
					// emette due eventi, 
					// con il primo (update) aggiorna la proprieta (che deve essere in .sync) 
					// con il secondo (change) richiama l'apposta funzione di refresh delle pagine ospite (lo stesso meccanismo usato dal controllo anno)
					this.$emit('update:annoCorrente', this.annoCorrente + 1)
					this.$emit('change:anno-corrente', {oldValue: this.annoCorrente, newValue: this.annoCorrente + 1})
				}
			}else {
				this.meseCorrente++
			}
		},
		downMeseCorrente() {
			if(this.meseCorrente === 1){
				this.meseCorrente = 12
				if(this.enableUpdateAnnoCorrente){
					// due eventi come descritto sopra
					this.$emit('update:annoCorrente', this.annoCorrente - 1)
					this.$emit('change:anno-corrente', {oldValue: this.annoCorrente, newValue: this.annoCorrente - 1})
				}
			}else {
				this.meseCorrente--
			}
		},
		decodificaTrimestre(codiceTrimestre) {
			var dummy = this.decodifiche.FormazioneTrimestre.find(i=>{return i.Key === codiceTrimestre})
			if(dummy) return dummy.Value
			return '-'
		},
		decodificaDescrizioneJob(idJob) {
			var dummy = this.decodifiche.Jobs.find(i=>{return i.Id === idJob})
			if(dummy) return dummy.DescrizioneJob
			return '-'
		},
		decodificaFinalita(codiceFinalita) {
			var dummy = this.decodifiche.FormazioneFinalita.find(i=>{return i.Key == codiceFinalita})
			if(dummy) return dummy.Value
			return '-'
		},
		decodificaNomeDocente(idDocente) {
			var docente = this.decodifiche.FormazioneCorsiDocenti.find(i => {return i.Id === idDocente})
			if(docente) return docente.Nome
			return '-'
		},
		decodificaTipoDocente(idDocente) {
			var docente = this.decodifiche.FormazioneCorsiDocenti.find(i => {return i.Id === idDocente})
			if(docente) return docente.Interno
			return '-'
		},
		decodificaNomeSede(idSede) {
			var sede = this.decodifiche.FormazioneCorsiSedi.find(i => {return i.Id === idSede})
			if(sede) return sede.Nome
			return '-'
		},
		// decodifica l'intero corso, serve per dare un po di dettaglio nel calendario
		decodificaCorso(idCorso) {
			var dummy = this.decodifiche.FormazioneCorsi.find(i=>{return i.Id == idCorso})
			if(dummy) return dummy
			return null
		},
		decodificaDateCorso(idCorso) {
			var dateCorso = this.decodificaCorso(idCorso).Date
			return lodash.orderBy(dateCorso, 'Inizio')
		},
		getEventColor (event) {
			return event.color
		},
		eventoCalendario({ nativeEvent, event }) {
			const open = () => {
				this.selectedEvent = event
				this.selectedElement = nativeEvent.target
				setTimeout(() => this.selectedOpen = true, 10)
			}
			if (this.selectedOpen) {
				this.selectedOpen = false
				setTimeout(open, 10)
			} else {
				open()
			}
			nativeEvent.stopPropagation()
		},
		// scatena l'evento di edit
		editEvent(event) {
			this.$emit('edit', event)
		}
	},
}
</script>

<style scoped lang="less">
	.opaque{ 
		opacity: 0.5;
	}
	.legacyTable {
		tr.selected {
			td {
				background-color: rgba(255, 187, 0, 0.3);
			}
		}
	}
</style>