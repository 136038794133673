<template>
	<div class="d-flex align-end">
		<v-btn fab text small :disabled="value<=lowerLimit" @click="down">
			<v-icon>mdi-chevron-left</v-icon>
		</v-btn>
		<div class="d-flex flex-column align-center " style="min-width:70px">
			<div class="caption opaque" style="margin-bottom">
				{{ label }}
			</div>
			<div class="headline mb-1" style="cursor: pointer" @click="reset">
				{{ value }}
			</div>
		</div>
		<v-btn fab text small :disabled="value>=upperLimit" @click="up">
			<v-icon>mdi-chevron-right</v-icon>
		</v-btn>
	</div>			
</template>

<script>
/**********************************************************************************************
**** COPONENTE PER LA GESTIONE DEL SELETTORE ANNO DI RIFERIENTO (FORMAZIONE) ******************
***********************************************************************************************
*/
import moment from 'moment'
export default {
	name: 'HrAnnoRiferimento',
	components: {  },
	props: {
		value: {
			type: Number,
			required: true,
			default: () => {return parseInt(moment().format('YYYY'))}
		},
		label: {
			type: String,
			required: false,
			default: () => {return 'Anno di riferimento'}
		},
		upperLimit: {
			type: Number,
			required: false,			
			default: () => {return parseInt(moment().format('YYYY')) + 10}
		},
		lowerLimit: {
			type: Number,
			required: false,			
			default: () => {return parseInt(moment().format('YYYY')) - 10}
		},
	},
	data: () => {
		return {
			valoreIniziale: 0
		}
	},
	computed: {
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
	},
	mounted() {
		this.valoreIniziale = this.value
	},
	methods: {
		change(newValue) {
			var oldValue = this.value
			this.$emit('input', newValue) // aggiorna v-model (e quindi anche this.value)
			this.$emit('change', {oldValue: oldValue, newValue: newValue}) // emette evento change con vecchio e nuovo valore
		},
		up() {
			if(this.value<this.upperLimit) this.change(this.value + 1)
		},
		down() {
			if(this.value>this.lowerLimit) this.change(this.value - 1)
		},
		reset() {
			this.change(this.valoreIniziale)
		},
	},
}
</script>

<style scoped lang="less">
	.opaque{ 
		opacity: 0.5;
	}
</style>