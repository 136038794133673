<template>
	<standard-container title="formazione" menuicon="formazione">
		<!-- header -->
		<template v-slot:header>
			<v-tabs v-model="currentTab" :show-arrows="true" background-color="transparent">
				<v-tab key="tabCorsi">
					<translated-text section="formazione.corsi" res="tab">Corsi</translated-text>
				</v-tab>
				<v-tab key="tabTotali">
					<translated-text section="formazione.totali" res="tab">Calcolo totali</translated-text>
				</v-tab>
				<v-tab key="tabCalendario">
					<translated-text section="formazione.calendario" res="tab">Calendario</translated-text> 
				</v-tab>
			</v-tabs>		
		</template>

		<!-- body slot -->
		<v-row :dense="compactVisualization">
			<v-col cols="12">
				<v-tabs-items v-model="currentTab">
					<!-- tabCorsi -->
					<v-tab-item key="tabCorsi">
						<v-row :dense="compactVisualization">
							<v-col cols="12">
								<div class="d-flex justify-space-between align-baseline mb-5">
									<hr-anno-riferimento v-model="annoCorrente" @change="changeAnnoCorrente" />			
									<div class="d-flex ">
										<v-text-field ref="searchField" v-model="search" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" autofocus prepend-inner-icon="mdi-magnify" label="Ricerca" hide-details clear-icon="mdi-close-circle" clearable />
										<v-select v-model="groupby" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" class="ml-5" label="Raggruppa per" :items="raggruppamenti" hide-details clear-icon="mdi-close-circle" clearable />
									</div>
								</div>
							</v-col>
						</v-row>
						<v-row :dense="compactVisualization">
							<v-col cols="12">
								<v-data-table dense
									:group-by.sync="groupby"
									:loading="loading"
									:items="formazioneConDecodifiche" 
									:headers="gTranslateTableHeader(tableHeader)"
									:search="search"
									:sort-by="['DataInizio']" 
									:sort-desc="[false]"
									item-key="Id"
									hide-default-footer
									disable-pagination
									show-expand
									:single-expand="false"
								>
									<template v-slot:item.Id="{ item }">
										<v-chip x-small label :color="item.CorsoColore" class="pl-0" />
									</template>
									<template v-slot:item.DocenteNome="{ item }">
										<div class="d-flex justify-space-between">
											<standard-label>{{ item.DocenteNome }}</standard-label>
											<div v-if="item.DocenteNome!==0" class="ml-2">
												<v-chip v-if="item.DocenteTipo" color="info" x-small label>
													interno
												</v-chip>
												<v-chip v-else color="" x-small label>
													esterno
												</v-chip>
											</div>
										</div>
									</template>
									<template v-slot:item.DataInizio="{ item }">
										<standard-label>{{ gShortDate(item.DataInizio) }}</standard-label>
									</template>
									<template v-slot:item.DataFine="{ item }">
										<standard-label>{{ gShortDate(item.DataFine) }}</standard-label>
									</template>
									<template v-slot:item.Finanziato="{ item }">
										<standard-label>{{ item.Finanziato ? 'Y': '-' }}</standard-label>
									</template>
									<template v-slot:item.CorsoSuperato="{ item }">
										<v-icon v-if="item.CorsoSuperato" color="success">
											mdi-check-decagram
										</v-icon>
										<v-icon v-else class="opaque2">
											mdi-check-decagram
										</v-icon>
									</template>
									<template v-slot:item.OreEffettuate="{ item }">
										<standard-label>{{ item.OreEffettuate }}</standard-label>
										<!--
										<span class="warning--text">
											<v-edit-dialog
												:return-value.sync="item.OreEffettuate"
												@save="oreEffettuateSaved(item)"
												@cancel="oreEffettuateCancelled"
												@open="oreEffettuateOpened"
												@close="oreEffettuateClosed"
											>
												{{ item.OreEffettuate }}
												<template v-slot:input>
													<v-text-field
														ref="oreEffettuate"
														v-model="item.OreEffettuate"
														label="Ore effettuate"
														single-line
														type="number"
														autofocus
													/>
												</template>
											</v-edit-dialog>										
										</span>
										-->
									</template>
									<template v-slot:item.ValutazioneCorso="{ item }">
										<v-rating dense readonly :value="item.ValutazioneCorso" :background-color="$vuetify.theme.dark ? 'grey darken-2' : 'grey lighten-2 '" :color="$vuetify.theme.dark ? 'white' : 'grey darken-1'" />
									</template>
									<template v-slot:expanded-item="{ headers, item }">
										<td :colspan="headers.length">
											<pre>
{{ gTranslate('Descrizione corso') }}: {{ item.DescrizioneCorso }}
{{ gTranslate('Descrizione docente') }}: {{ item.DocenteDescrizione }}
{{ gTranslate('Descrizione sede') }}: {{ item.SedeDescrizione }}
											</pre>
										</td>
									</template>
								</v-data-table>
							</v-col>
						</v-row>
					</v-tab-item>
					<v-tab-item key="tabTotali">
						<v-row :dense="compactVisualization">
							<v-col cols="12">
								<div class="d-flex justify-space-between align-baseline mb-5">
									<hr-anno-riferimento v-model="annoCorrente" @change="changeAnnoCorrente" />		
									<div />
								</div>
							</v-col>
						</v-row>
						<v-row :dense="compactVisualization">
							<v-col cols="12">
								<v-simple-table :class="loading ? 'opaque' : ''">
									<template v-slot:default>
										<thead>
											<tr>
												<th class="text-center blue-grey title">
													{{ gTranslate('Timestre') }} {{ annoCorrente }}
												</th>
												<th class="text-center teal title" colspan="2">
													{{ gTranslate('Formazione interna') }}
												</th>
												<th class="text-center blue title" colspan="2">
													{{ gTranslate('Formazione esterna') }}
												</th>
												<th class="text-center orange title" colspan="2">
													{{ gTranslate('Totale') }} {{ annoCorrente }}
												</th>
												<th class="text-center grey darken-2 body-1">
													{{ gTranslate('On the job') }}
												</th>
												<th class="text-center grey darken-2 body-1">
													{{ gTranslate('Trasversale') }}
												</th>
												<th class="text-center grey darken-2 body-1">
													{{ gTranslate('Coaching') }}
												</th>
												<th class="text-center grey darken-3 body-1">
													{{ gTranslate('Finanziata') }}
												</th>
											</tr>
											<tr>
												<th class="text-left blue-grey" />
												<th class="text-left teal body-1">
													{{ gTranslate('Ore') }}
												</th>
												<th class="text-right teal body-1">
													{{ gTranslate('Costo') }}
												</th>
												<th class="text-left blue body-1">
													{{ gTranslate('Ore') }}
												</th>
												<th class="text-right blue body-1">
													{{ gTranslate('Costo') }}
												</th>
												<th class="text-left orange body-1">
													{{ gTranslate('Ore') }}
												</th>
												<th class="text-right orange body-1">
													{{ gTranslate('Costo') }}
												</th>
												<th class="text-right grey darken-2">
													{{ gTranslate('ore / costo') }}
												</th>
												<th class="text-right grey darken-2">
													{{ gTranslate('ore / costo') }}
												</th>
												<th class="text-right grey darken-2">
													{{ gTranslate('ore / costo') }}
												</th>
												<th class="text-right grey darken-3">
													{{ gTranslate('ore / costo') }}
												</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="item in totali.Trimestri" :key="item.Trimestre">
												<td class="text-center blue-grey lighten-1 title">
													{{ item.Trimestre }}
												</td>
												<td class="teal lighten-1">
													{{ item.OreEffettuateInterno }}
												</td>
												<td class="text-right teal lighten-1">
													{{ gToCurrency(item.CostoInterno) }}
												</td>
												<td class="blue lighten-1">
													{{ item.OreEffettuateEsterno }}
												</td>
												<td class="text-right blue lighten-1">
													{{ gToCurrency(item.CostoEsterno) }}
												</td>
												<td class="orange lighten-1">
													{{ item.OreEffettuateTotali }}
												</td>
												<td class="text-right orange lighten-1">
													{{ gToCurrency(item.CostoTotale) }}
												</td>
												<td class="text-right grey darken-1 text-no-wrap">
													{{ item.OreOnthejobTotali }} / {{ gToCurrency(item.CostoOnthejobTotale) }}
												</td>
												<td class="text-right grey darken-1 text-no-wrap">
													{{ item.OreTrasversaleTotali }} / {{ gToCurrency(item.CostoTrasversaleTotale) }}
												</td>
												<td class="text-right grey darken-1 text-no-wrap">
													{{ item.OreFinalita30Totali }} / {{ gToCurrency(item.CostoFinalita30Totale) }}
												</td>
												<td class="text-right grey darken-2 text-no-wrap">
													{{ item.OreFinanziatoTotali }} / {{ gToCurrency(item.CostoFinanziatoTotale) }}
												</td>
											</tr>
										</tbody>
										<tfoot>
											<tr>
												<td class="blue-grey darken-1" />
												<td class="title teal darken-1">
													{{ totali.TotaleOreEffettuateInterno }}
												</td>
												<td class="text-right teal darken-1 title">
													{{ gToCurrency(totali.TotaleCostoInterno) }}
												</td>
												<td class="title blue darken-1">
													{{ totali.TotaleOreEffettuateEsterno }}
												</td>
												<td class="text-right blue darken-1 title">
													{{ gToCurrency(totali.TotaleCostoEsterno) }}
												</td>
												<td class="orange darken-1 title">
													{{ totali.TotaleOreEffettuate }}
												</td>
												<td class="text-right orange darken-1 title">
													{{ gToCurrency(totali.TotaleCosto) }}
												</td>
												<td class="text-right grey darken-2">
													{{ totali.TotaleOreOnthejob }} / {{ gToCurrency(totali.TotaleCostoOnthejob) }}
												</td>
												<td class="text-right grey darken-2">
													{{ totali.TotaleOreTrasversale }} / {{ gToCurrency(totali.TotaleCostoTrasversale) }}
												</td>
												<td class="text-right grey darken-2">
													{{ totali.TotaleOreFinalita30 }} / {{ gToCurrency(totali.TotaleCostoFinalita30) }}
												</td>
												<td class="text-right grey darken-3">
													{{ totali.TotaleOreFinanziato }} / {{ gToCurrency(totali.TotaleCostoFinanziato) }}
												</td>											
											</tr>
										</tfoot>
									</template>
								</v-simple-table>
							</v-col>						
						</v-row>
					</v-tab-item>
					<v-tab-item key="tabCalendario">
						<hr-calendario-corsi :loading="loading" :value="calendario" :enable-update-anno-corrente="true" :anno-corrente.sync="annoCorrente" :show-label="false" :solo-data-corrente="true" @change:anno-corrente="changeAnnoCorrente">
							<template v-slot:header>
								<hr-anno-riferimento v-model="annoCorrente" @change="changeAnnoCorrente" />		
							</template>
						</hr-calendario-corsi>
					</v-tab-item>
				</v-tabs-items>
			</v-col>
		</v-row>
		
		<!-- footer -->
		<template v-slot:footer>
			<v-row>
				<v-col cols="12">
					<v-btn small icon :title="gTranslate('Ricarica la lista dal server')" :disabled="loading" :loading="loading" @click="refresh">
						<v-icon>mdi-refresh</v-icon>
					</v-btn>
				</v-col>
			</v-row>
		</template>

		<!-- debug -->
		<template v-slot:debug>
			<v-row>
				<v-col cols="6">
					<debug-panel label="formazione">
						{{ formazioneConDecodifiche }}
					</debug-panel>
				</v-col>
				<v-col cols="6">
					<debug-panel label="totali">
						{{ totali }}
					</debug-panel><debug-panel class="mt-5" label="calendario">
						{{ calendario }}
					</debug-panel>
				</v-col>
			</v-row>
		</template>

		<v-snackbar v-model="snackbarVisible" :color="snackbarColor" top :timeout="4000">
			{{ snackbarText }}
		</v-snackbar>		
	</standard-container>
</template>

<script>
/**********************************************************************************************
**** FORMAZIONE *******************************************************************************
***********************************************************************************************
* contrariamente alle altre pagine standard questa preleva i dati al volo senza appoggiarsi allo store
*/
import StandardContainer from '@/components/standard-container'
import DebugPanel from '@/components/debug-panel'
// import DebugContainer from '@/components/debug-container'
import StandardLabel from '@/components/standard-label'
import HrAnnoRiferimento from '@/components/hr-anno-riferimento'
import HrCalendarioCorsi from '@/components/hr-calendario-corsi'
import moment from 'moment'
export default {
	name: 'FormazionePage',
	components: { StandardContainer, DebugPanel, StandardLabel, HrAnnoRiferimento, HrCalendarioCorsi },
	data: () => ({
		currentTab:0,
		search: '',
		groupby: null,
		annoCorrente: parseInt(moment().format('YYYY')),
		dialog: false,
		loading: false,
		snackbarVisible: false,
		snackbarText: '',
		snackbarColor: 'info',
		formazione: [],
		totali: [],
		calendario: {},
		tableHeader: [
			{ text: '', value: 'Id' },
			{ text: 'Corso', value: 'CodiceCorso', groupable: true },
			{ text: 'Job', value: 'JobNome', groupable: true },
			{ text: 'Finalità', value: 'FinalitaFormazione', groupable: true },
			{ text: 'Persona', value: 'PersonaFullName', groupable: true },
			{ text: 'Docente', value: 'DocenteNome', groupable: true },
			{ text: 'Sede', value: 'SedeNome', groupable: true },
			{ text: 'Trimestre', value: 'Trimestre', groupable: true },
			{ text: '', value: 'NomeDataCorso', groupable: true },
			{ text: 'Inizio', value: 'DataInizio' },
			{ text: 'Fine', value: 'DataFine' },
			{ text: 'Ore t.', value: 'OreData' },
			{ text: 'Ore e.', value: 'OreEffettuate' },
			{ text: 'Costo', value: 'Costo' },
			{ text: 'Finanziato', value: 'Finanziato', groupable: true },
			{ text: 'Superato', value: 'CorsoSuperato', groupable: true },
			{ text: 'Feedback corso', value: 'ValutazioneCorso', groupable: true },
		],

	}),
	computed: {
		appSetup() { return window.setup },
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		tce() { return this.$store.getters['tipologiaCampiEdit'] },
		compactVisualization() { return this.$store.getters['compactVisualization'] },
		persone() { return this.$store.getters['persone'] },
		decodifiche() { return this.$store.getters['decodifiche'] },
		raggruppamenti() {
			var result = []
			this.tableHeader.forEach(element => {
				if(element.groupable) result.push(element)	
			})
			return result
		},
		formazioneConDecodifiche() {
			this.formazione.forEach(item => {
				// decodifica persona - solo i campi necessari per non far esplodere il browser! :-)
				var persona = this.persone.find(i => {return i.Id === item.IdPersona})
				this.$set(item, 'PersonaFullName', persona ? persona.FullName: item.IdPersona) // se non trova la persona ne visualizza il codice
				if (item.DataCorso && item.DataCorso.Corso) {
					// recupera date corso e altri dati che non richiedono decodifiche
					this.$set(item, 'CodiceCorso', item.DataCorso.Corso.Codice ) 
					this.$set(item, 'DescrizioneCorso', item.DataCorso.Corso.Descrizione ) 
					this.$set(item, 'NomeDataCorso', item.DataCorso.Nome ) 
					this.$set(item, 'DataInizio', item.DataCorso.Inizio ) 
					this.$set(item, 'DataFine', item.DataCorso.Fine ) 
					this.$set(item, 'CorsoColore', item.DataCorso.Corso.Colore ) 
					this.$set(item, 'Finanziato', item.DataCorso.Corso.Finanziato ) 
					this.$set(item, 'OreData', item.DataCorso.OreData ) 
					// costo viene preso da datacorso e, se non disponibile, da corso
					var costo = item.DataCorso.CostoData && item.DataCorso.CostoData>0 ? item.DataCorso.CostoData : item.DataCorso.Corso.Costo
					this.$set(item, 'Costo', costo) 
					// decodifica trimestre
					var trimestre = this.decodifiche.FormazioneTrimestre.find(i => {return i.Key===item.DataCorso.Trimestre})
					this.$set(item, 'Trimestre', trimestre ? trimestre.Value : item.DataCorso.Trimestre ) 
					// decodifica finalità
					var finalita = this.decodifiche.FormazioneFinalita.find(i => {return i.Key==item.DataCorso.Corso.CodiceFinalita})
					this.$set(item, 'FinalitaFormazione', finalita ? finalita.Value : item.DataCorso.Corso.CodiceFinalita ) 
					// decodifica job
					var idJob = item.DataCorso.Corso.IdJob
					var job = this.decodifiche.Jobs.find(i => {return i.Id === idJob})
					this.$set(item, 'JobNome', job ? job.DescrizioneJob : idJob ) // se non trova il job ne visualizza il codice
					// decodifica docente
					var idDocente = item.DataCorso.IdDocente
					if (idDocente<=0) idDocente = item.DataCorso.Corso.IdDocentePrincipale
					var docente = this.decodifiche.FormazioneCorsiDocenti.find(i => {return i.Id === idDocente})
					this.$set(item, 'DocenteNome', docente ? docente.Nome : idDocente )
					this.$set(item, 'DocenteDescrizione', docente ? docente.Descrizione : idDocente )
					this.$set(item, 'DocenteTipo', docente ? docente.Interno : false )
					// decodifica sede
					var idSede = item.DataCorso.IdSede
					if (idSede<=0) idSede = item.DataCorso.Corso.IdSedePrincipale
					var sede = this.decodifiche.FormazioneCorsiSedi.find(i => {return i.Id === idSede})
					this.$set(item, 'SedeNome', sede ? sede.Nome : idSede )
					this.$set(item, 'SedeDescrizione', sede ? sede.Descrizione : idSede )
					// alla fine sbianca la subcollection per ridurre il peso della lista
					item.DataCorso = {}
				} else {
					// se manca la struttura corso visualizza le decodifiche con un trattino
					this.$set(item, 'CodiceCorso', '-' )
					this.$set(item, 'DescrizioneCorso', '-' )
					this.$set(item, 'CorsoColore', '#000' )
					this.$set(item, 'FinalitaFormazione', '-')
					this.$set(item, 'JobNome', '-' ) 
					this.$set(item, 'DocenteNome', '-' )
					this.$set(item, 'DocenteDescrizione', '-' )
					this.$set(item, 'DocenteTipo', false )
					this.$set(item, 'SedeNome', '-' )
					this.$set(item, 'SedeDescrizione', '-' )
				}
			})
			return this.formazione
		}
	},
	mounted() {
		this.refresh()
	},
	methods: {
		changeAnnoCorrente() {
			// console.warn('changeAnnoCorrente', event)
			this.refresh()
		},
		// aggiorna i dati con il nuovo anno corrente
		refresh() {
			this.loading = true
			this.$store.dispatch('genericApiPost', {apiUrl: '/Formazione/List', value: {Anno: this.annoCorrente}}).then(data => {
				this.formazione = data.Formazione
				this.totali = data.Totali
				this.calendario = data.Calendario
				this.loading = false
			}).catch(error => {
				console.error('refresh', error)
			})
		},
		oreEffettuateSaved(item) { 
			// console.warn('oreEffettuateSaved', item) 
			this.$store.dispatch('genericApiPost', {apiUrl: '/Formazione/SetOre', value: item}).then(data => {
				item.OreEffettuate = data.OreEffettuate
			}).catch(error => {
				console.error('refresh', error)
			})
		},
		oreEffettuateCancelled() { console.warn('oreEffettuateCancelled') },
		oreEffettuateOpened() { 
			// console.warn('oreEffettuateOpened') 
			setTimeout(() => { 
				this.$refs.oreEffettuate.focus() 
				console.warn('focus')
			},500)
			
		},
		oreEffettuateClosed() { console.warn('oreEffettuateClosed') },
	}
}
</script>

<style scoped lang="less">
	.opaque{ 
		opacity: 0.5;
	}
	.opaque2{ 
		opacity: 0.1;
	}
</style>